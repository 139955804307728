import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Categories from 'redux/categories';
import style from './index.module.scss';
import Pages from 'redux/pages';
import arrowOpenedCategory from 'assets/icons/arrow-open-category.svg';
import arrowClosedCategoryEn from 'assets/icons/arrow-closed-category-en.svg';
import arrowClosedCategoryAr from 'assets/icons/arrow-closed-category-ar.svg';
import arrowOpenPage from 'assets/icons/arrow-open-page.svg';
import { useTranslation } from 'react-i18next';

const RuleBookItem = ({ parentId, depth = 1 }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const history = useHistory();
  const categories = useSelector((state) => Categories.selectors.getCategories(state));
  const pages = useSelector((state) => Pages.selectors.getPages(state));

  const [openMap, setOpenMap] = useState({});

  const getFontSizeForCategory = () => {
    if (depth === 1) return '22px';
    if (depth === 2) return '20px';
    else return '18px';
  };

  const getFontSizeForPage = () => {
    if (depth >= 2) return '18px';
    return '20px';
  };

  const fetchCategories = (parentCategory) => {
    if (parentCategory !== 'root') {
      dispatch(Categories.thunks.getAllCategories({ parent_id: parentCategory }));
      dispatch(Pages.thunks.getAllPages({ category_id: parentCategory, show_all: true, context: 'RULE_BOOK' }));
    } else {
      dispatch(Categories.thunks.getAllCategories());
    }
  };

  const redirectToPage = (path) => {
    history.push(path);
  };

  const pageIsOpened = (pageId) => {
    const id = history.location.pathname.split('/').pop();
    return pageId === id;
  };

  const onClick = (categoryId, event) => {
    if (!categories[categoryId]) fetchCategories(categoryId);
    setOpenMap((prevState) => ({
      ...prevState,
      [categoryId]: !openMap[categoryId],
    }));
    event.stopPropagation();
  };

  useEffect(() => {
    if (!categories[parentId]?.length) fetchCategories(parentId);
  }, [parentId, categories]);

  const renderArrowClosed = () => (i18n.language === 'en' ? arrowClosedCategoryEn : arrowClosedCategoryAr);

  const renderItemChildren = (categoryId) => (
    <div>
      {categories[categoryId] && <RuleBookItem parentId={categoryId} depth={depth + 1} />}
      {pages[categoryId] &&
        pages[categoryId].map((page, index) => (
          <div
            className={`${style.ruleBookContentPageName} ${pageIsOpened(page.id) ? style.open : ''}`}
            onClick={(event) => {
              redirectToPage(`/page/${page.id}`);
              event.stopPropagation();
            }}
            key={index}
            style={{ fontSize: getFontSizeForPage() }}
          >
            {pageIsOpened(page.id) && <img src={arrowOpenPage} />}
            {page[`title_${i18n.language}`]}
          </div>
        ))}
    </div>
  );

  const renderItems = (parentId) => {
    if (categories[parentId]?.length > 0) {
      return categories[parentId].map((cat, index) => (
        <div
          key={index}
          className={style.ruleBookContentItem}
          onClick={(event) => {
            onClick(cat.id, event);
          }}
          style={{ fontSize: getFontSizeForCategory() }}
        >
          <div className={style.ruleBookContentCatName}>
            <span>{cat[`title_${i18n.language}`]}</span>
            <img src={openMap[cat.id] ? arrowOpenedCategory : renderArrowClosed()} />
          </div>
          {openMap[cat.id] && <div className={style.ruleBookContentItemChildren}>{renderItemChildren(cat.id)}</div>}
        </div>
      ));
    } else {
      return null;
    }
  };

  return renderItems(parentId);
};

export default RuleBookItem;
