import { createAsyncThunk } from '@reduxjs/toolkit';
import MenuItemsAPI from './services';

export const getMenuItem = createAsyncThunk('menu_items/getMenuItem', async (id, thunkAPI) => {
  try {
    const response = await MenuItemsAPI.getMenuItem(id);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const getAllMenuItems = createAsyncThunk('menu_items/getAllMenuItems', async (params, thunkAPI) => {
  try {
    const response = await MenuItemsAPI.getAllMenuItems(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
