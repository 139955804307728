import { useEffect } from 'react';
import { Dropdown, Space, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MenuItems from 'redux/menu_items';
import style from './index.module.scss';
import { useTranslation } from 'react-i18next';

const MenuItem = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const history = useHistory();
  const menuItems = useSelector((state) => MenuItems.selectors.getMenuItems(state));

  useEffect(() => {
    fetchMenuItems();
  }, [i18n.language]);

  const fetchMenuItems = () => {
    dispatch(MenuItems.thunks.getAllMenuItems());
  };

  const redirectToPage = (menuItem) => {
    const { link, link_type, has_children } = menuItem;
    const isExternal = link_type === 'external';
    if (link !== null) {
      if (!has_children) {
        if (isExternal) {
          window.open(link, '_blank');
        } else {
          history.replace(link.startsWith('/') ? link : `/${link}`);
        }
      }
    }
  };

  const handleMenuClick = (e, menuItem) => {
    e.domEvent.stopPropagation();
    if (menuItem.has_children && menuItem.children) {
      redirectToPage(menuItem);
    } else {
      redirectToPage(menuItem);
    }
  };

  const renderMenu = (items = []) => {
    return items.map((item) => {
      if (item.has_children && item.children) {
        return (
          <Menu.SubMenu key={item.id} title={item.name} onTitleClick={(e) => handleMenuClick(e, item)}>
            {renderMenu(item.children)}
          </Menu.SubMenu>
        );
      }

      return (
        <Menu.Item key={item.id} onClick={(e) => handleMenuClick(e, item)}>
          {item.name}
        </Menu.Item>
      );
    });
  };

  const renderItems = () => {
    return (
      <>
        {menuItems &&
          menuItems.map((item) => {
            const hasChildren = item.has_children && item.children?.length > 0;
            return (
              <Dropdown
                key={item.id}
                overlay={<Menu>{hasChildren ? renderMenu(item.children) : null}</Menu>}
                trigger={hasChildren ? ['click'] : []}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    redirectToPage(item);
                  }}
                  className={style.itemLabel}
                >
                  <Space>{item[`name_${i18n.language}`]}</Space>
                </a>
              </Dropdown>
            );
          })}
      </>
    );
  };
  return renderItems();
};

export default MenuItem;
