import axios from '../../utils/requestHelper';

const getMenuItem = (id) => axios.get(`/guests/menu_items/${id}`);

const getAllMenuItems = (params) =>
  axios.get(`/guests/menu_items`, { params: { ...params, includes: 'parent,grand_parent' } });

const MenuItemsAPI = {
  getMenuItem,
  getAllMenuItems,
};

export default MenuItemsAPI;
