import style from './index.module.scss';
import MenuItem from 'components/MenuItem';
import { useMedia } from 'react-use';

const MenuItemsBar = ({ children }) => {
  const mdScreen = useMedia(`(max-width: 768px)`);

  return (
    !mdScreen && (
      <div className={style.menuItemsWrapper}>
        <aside className={style.menuItems}>
          <div className={style.menuItemsContent}>
            <MenuItem />
          </div>
        </aside>
        <div className={style.menuItemsChildren}>{children}</div>
      </div>
    )
  );
};

export default MenuItemsBar;
