import { Layout } from 'antd';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useLocation, useHistory } from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';
import './index.scss';
import MenuItemsBar from 'components/MenuItemsBar';
import RuleBook from 'components/RuleBook';
import Navigation from 'components/Navigation';

const DefaultLayout = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  let page_id = history.location.pathname.split('/').pop();

  return (
    <Layout>
      <Header />
      <Layout.Content>
        <TransitionGroup>
          <CSSTransition key={location} timeout={1000} classNames="fade">
            <div className="site-layout">
              <MenuItemsBar />
              <RuleBook>
                {children}
                {page_id && <Navigation PageID={page_id} />}
              </RuleBook>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Layout.Content>
      <Footer />
    </Layout>
  );
};

export default DefaultLayout;
