import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { set } from 'lodash';
import { getAllMenuItems } from './thunks';

export const slice = createSlice({
  name: 'menuItems',
  initialState: { entries: [] },
  extraReducers: (builder) => {
    builder.addCase(getAllMenuItems.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;

      if (!data.menu_items) return;
      state.entries = data.menu_items;
    });
  },
});

const MenuItems = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default MenuItems;
