import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const menuItemSelectors = {
  ...adapter.getSelectors((state) => state.menu_items),
};

export const getMenuItems = createSelector(
  (state) => state?.menu_items,
  (menu_items) => menu_items.entries,
);

export const getMenuItemsById = createSelector(
  (state) => state?.menu_items,
  (_, menuItemId) => menuItemId,
  (menu_items, menuItemId) => menu_items[menuItemId] || menu_items.root,
);
